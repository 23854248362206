'use strict';

(function(window, document){

    var banner__breakpoint = window.matchMedia('(min-width: 768px)');
    var isIE = document.documentElement.browser === 'IE';

    $(document).ready(function() {

        //  KV
		var setImg = function(imageEl) {
			if(imageEl.rwdImg === undefined) return false;

			var useSrc = banner__breakpoint.matches ? imageEl.rwdImg.m : imageEl.rwdImg.s;

			if(isIE) {
				imageEl.style.backgroundImage = 'url(' + useSrc + ')'
			} else {
				imageEl.src = useSrc
            }
        }
        
        var bannerSlide = '#slide_banner .swiper-container';
        if($(bannerSlide).length){
            var bannerSwiper = new Swiper(bannerSlide, {
                loop: true,
                slidesPerView: 'auto',
                spaceBetween: 15,
                // centeredSlides : true,
                centeredSlidesBounds: true,
                loadPrevNext: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '#slide_banner .swiper-arrow_right',
                    prevEl: '#slide_banner .swiper-arrow_left'
                },
                pagination: {
                    el: '#slide_banner .swiper-pagination',
                    clickable: true
                },
                // breakpoints: {
                //     768: {
                //         slidesPerView: 'auto',
                //         spaceBetween: 15,
                //         centeredSlides : true,
                //         centeredSlidesBounds: true,
                //     },
                // },
                lazyLoading: true,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                on: {
                    lazyImageLoad: function (slideEl, imageEl) {
                        imageEl.rwdImg = {
                            s: imageEl.dataset.srcSmall,
                            m: imageEl.dataset.srcDefault
                        }
                        setImg(imageEl);
                    }
                }
            });

            if (bannerSwiper.slides.length <= 3 ) {
                bannerSwiper.autoplay.stop();
                bannerSwiper.allowTouchMove = false;
                $('#slide_banner').addClass('is-prevent')
            }

            var banner__breakpointCheck = function() {
                var slides = bannerSwiper.slides;
                for(var i = 0; i < slides.length; i++) {
                    var imgEl = slides[i].querySelector('img');
                    if(imgEl.classList.contains('swiper-lazy-loaded')) {
                        setImg(imgEl);
                    }
                }
            }

            banner__breakpoint.addListener(banner__breakpointCheck);
        }

        //  排行榜
        var topSaleSlide = '#slide_top-sale .swiper-container';
        if($(topSaleSlide).length){
            var top_sale_slide = new Swiper(topSaleSlide, {
                loop: false,
                slidesPerView: 2,
                spaceBetween: 15,
                navigation: {
                    nextEl: '#slide_top-sale .swiper-arrow_right',
                    prevEl: '#slide_top-sale .swiper-arrow_left'
                },
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    992: {
                        slidesPerView: 5,
                        spaceBetween: 15
                    },
                },
                preloadImages: false,
                lazyLoading: true,
                lazy: {
                    loadPrevNext: true,
                },
                on: {
                    init: function name(params) {
                        var equalizer = new Foundation.Equalizer($('#slide_top-sale'));
                    },
                    slideChange: function () {},
                }
            });
        }

        //  推薦食譜
        var recommendRecipeSlide = '#slide_recommend-recipe .swiper-container';
        if($(recommendRecipeSlide).length){
            var recommend_slide = new Swiper(recommendRecipeSlide, {
                loop: true,
                slidesPerView: 2,
                spaceBetween: 15,
                navigation: {
                    nextEl: '#slide_recommend-recipe .swiper-arrow_right',
                    prevEl: '#slide_recommend-recipe .swiper-arrow_left'
                },
                pagination: {
                    el: '#slide_recommend-recipe .swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    1024: {
                        spaceBetween: 36
                    },
                },
                preloadImages: false,
                lazyLoading: true,
                lazy: {
                    loadPrevNext: true,
                },
                on: {
                    init: function name(params) {
                        var equalizer = new Foundation.Equalizer($('#slide_recommend-recipe'));
                    },
                    slideChange: function () {},
                }
            });
        }
    });


    $(window).on('load', function() {
        if($('.masonry-wrapper').length) {
            $('.masonry-wrapper').masonry({
                // set itemSelector so .grid-sizer is not used in layout
                itemSelector: '.panel__box',
                // use element for option
                columnWidth: '.grid-sizer',
                percentPosition: true,
                gutter: '.gutter-sizer',
                horizontalOrder: true
            });
        }
     });
})(window, document);
